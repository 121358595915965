import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Box,
  makeStyles,
  IconButton,
  Container,
  Button,
  Popover,
} from "@material-ui/core";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import CalendarIcon from "mdi-react/CalendarIcon";
import PlusIcon from "mdi-react/PlusCircleIcon";
import Slot from "../Slot";
import API from "../../utils/API";
import Moment from "react-moment";
import moment from "moment";
import "moment/locale/de";
import clsx from "clsx";
import useScreensize from "../../hooks/useScreensize";
import Pusher from "react-pusher";
import MuiAlert from "@material-ui/lab/Alert";
import ReactMarkdown from "react-markdown";
import * as breakingNewsActions from "../../store/actions/breakingNewsActions";
import * as locationActions from "../../store/actions/locationActions";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "react-calendar";
import "../Calendar.css";
import SlotEdit from "../SlotEdit";
import LoadingIcon from "mdi-react/LoadingIcon";
import { blueGrey } from "@material-ui/core/colors";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.secondary.main,
    backgroundSize: "cover",
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  arrow: {
    width: 36,
    height: 36,
  },
  wrapper: {
    maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto",
  },
  btnDisabled: {
    color: "rgba(255,255,255,0.3) !important",
  },
  introText: {
    opacity: 0.85,
  },
  calendarBtn: {
    position: "absolute",
    right: 60,
  },
  dot: {
    display: "block",
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: "transparent",
    margin: "2px auto 0 auto",
  },
  dotAvailable: {
    backgroundColor: "#0b0",
  },
  dotNotAvailable: {
    backgroundColor: "#c00",
  },
  iconCreate: {
    width: 50,
    height: 50
  },
  loadingIconWrapper: {
    display: "flex",
    justifyContent: "center"
  },
  loadingIcon: {
    color: blueGrey[500],
    width: 40,
    height: 40,
    animation: "spin 1s linear infinite"
  },
}));

const today = moment().format("YYYY-MM-DD");

const pusherTimeouts = [];

const Home = () => {
  const classes = useStyles();
  const [date, setDate] = useState(null);
  const [slots, setSlots] = useState(null);
  const [loading, setLoading] = useState(false);
  const { xs, sm, md, lg, xl } = useScreensize();
  const breakingNews = useSelector((state) => state.breakingNews);
  const dispatch = useDispatch();
  const dateRef = useRef(date);
  const businessUnit = useSelector((state) => state.businessUnit);
  const [anchorEl, setAnchorEl] = useState(null);
  const [availabilityInformation, setAvailabilityInformation] = useState({});
  const availRef = useRef(availabilityInformation);
  const [openCreate, setOpenCreate] = useState(false);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    dateRef.current = date;
  }, [date]);

  useEffect(() => {
    availRef.current = availabilityInformation;
  }, [availabilityInformation]);

  useEffect(() => {
    if (!businessUnit) {
      return;
    }
    if (!date) {
      const minStartDate = businessUnit.MinFirstDate || null;
      let today;
      if (moment().day() === 0 && businessUnit.SkipSundays) {
        today = moment().add(1, "day").format("YYYY-MM-DD");
      } else {
        today = moment().format("YYYY-MM-DD");
      }

      if (!minStartDate || minStartDate < today) {
        setDate(today);
      } else {
        setDate(minStartDate);
      }
    }

    getAvailabilityInformation();

    return () => {
      for (const t of pusherTimeouts) {
        if (t) {
          clearTimeout(t);
        }
      }
      pusherTimeouts.length = 0;
    };
  }, [businessUnit]);

  useEffect(() => {
    if (!date) {
      return;
    }

    loadSlots();
  }, [date]);

  const loadSlots = () => {
    console.log("LOAD...");
    setLoading(true);

    const headers = {};

    if (auth && auth.token) {
      headers.Authorization = `Bearer ${auth.token}`;
    }

    API.get("slots/date/" + date, { headers })
      .then((slotsData) => {
        //console.log(slotsData);
        const slotsResult = slotsData.data ? slotsData.data : [];
        setSlots(slotsResult);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSlots([]);
      });
  }

  const handleOpenCalendar = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setAnchorEl(null);
  };

  const handlePrev = () => {
    if (loading) {
      return;
    }
    const current = date;
    let prev = moment(current).subtract(1, "day").format("YYYY-MM-DD");
    if (moment(prev).day() === 0 && businessUnit.SkipSundays) {
      prev = moment(prev).subtract(1, "day").format("YYYY-MM-DD");
    }
    setDate(prev);
  };

  const handleNext = () => {
    if (loading) {
      return;
    }
    const current = date;
    let next = moment(current).add(1, "day").format("YYYY-MM-DD");
    if (moment(next).day() === 0 && businessUnit.SkipSundays) {
      next = moment(next).add(1, "day").format("YYYY-MM-DD");
    }
    setDate(next);
  };

  const handlePusher = (e) => {
    // console.log(e, e.message, dateRef.current);
    if (!e.message) {
      return;
    }
    const pusherDates = [...new Set(e.message.split(","))];
    if (!pusherDates || !pusherDates.length) {
      return;
    }

    // console.log("PUSHER", pusherDates);

    const usedPusherDates = pusherDates.filter((f) => {
      // console.log(availRef.current);
      if (!moment(f).isValid()) {
        return false;
      }

      if (f === dateRef.current) {
        return true;
      }

      if (availRef.current.hasOwnProperty(f) && availRef.current[f] < 5) {
        return true;
      }

      return false;
    });

    if (usedPusherDates && usedPusherDates.length) {
      pusherTimeouts[pusherTimeouts.length] = setTimeout(() => {
        handleUpdateSeats(usedPusherDates);
      }, Math.random() * 2000);
    }
    // getAvailabilityInformation();
  };

  const getAvailabilityInformation = () => {
    API.get("slots/dates/availability")
      .then((slotsData) => {
        // console.log(slotsData);
        const slotsResult = slotsData.data ? slotsData.data : {};
        setAvailabilityInformation(slotsResult);
      })
      .catch((error) => { });
  };

  const updateAvailabilityInformation = (updatedSlots) => {
    if (!updatedSlots || !updatedSlots.length) {
      return;
    }
    const updatedDates = [...new Set(updatedSlots.map((m) => m.Date))];
    setAvailabilityInformation((state) => {
      const newAvail = Object.assign({}, state);
      for (const d of updatedDates) {
        if (newAvail.hasOwnProperty(d)) {
          newAvail[d] =
            updatedSlots.reduce((a, b) => a + b.AvailableSeats, 0) > 0;
        }
      }
      return newAvail;
    });
  };

  const handleUpdateSeats = (dates) => {
    const headers = {};

    if (auth && auth.token) {
      headers.Authorization = `Bearer ${auth.token}`;
    }

    API.get("slots/date/" + dates.join("_"), { headers })
      .then((slotsData) => {
        // console.log(slotsData);
        const slotsResult = slotsData.data ? slotsData.data : [];
        const current = slotsResult.filter((f) => f.Date === dateRef.current);
        if (current && current.length) {
          setSlots(current);
        }
        updateAvailabilityInformation(slotsResult);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSlots([]);
      });
  };

  const handleCloseAlert = () => {
    dispatch(breakingNewsActions.close());
  };

  const onChangeCalendar = (d) => {
    setDate(moment(d).format("YYYY-MM-DD"));
    handleCloseCalendar();
  };

  const handleOpenCreate = () => {
    setOpenCreate(true);
  }

  const handleCloseCreate = () => {
    console.log("CLOSE...");
    setOpenCreate(false);
    loadSlots();
  }

  if (!businessUnit) {
    return null;
  }

  const calendarOpen = Boolean(anchorEl);
  const calendarId = calendarOpen ? "calendar-popover" : undefined;

  return (
    <>
      <Box
        className={classes.header}
        style={{ background: businessUnit.ColorHeaderBackground }}
        pt={4}
        pb={6}
        px={0.5}
        color="white"
      >
        <Box px={2} pb={{ md: 2 }} pt={{ md: 2 }}>
          <Typography
            variant="h1"
            align="center"
            gutterBottom
            style={{ color: businessUnit.ColorHeaderText }}
          >
            {businessUnit.MainTitle}
          </Typography>
        </Box>
        <Box px={2} pb={{ md: 4 }} className={classes.wrapper}>
          <Typography
            variant={xs ? "body2" : "body1"}
            align="center"
            component="div"
            className={classes.introText}
            style={{ color: businessUnit.ColorHeaderText }}
          >
            <strong>{businessUnit.Title}</strong>
            <ReactMarkdown source={businessUnit.IntroText} />
          </Typography>
        </Box>
        {breakingNews && !breakingNews.hidden && breakingNews.content && (
          <Container maxWidth="md">
            <Box py={2}>
              <Alert
                icon={false}
                severity="warning"
                onClose={handleCloseAlert}
                style={{
                  backgroundColor: businessUnit.ColorWarningBoxBackground,
                }}
              >
                <Box textAlign="center" px={6}>
                  <Typography
                    variant="body1"
                    component="div"
                    style={{
                      color: businessUnit.ColorWarningBoxText,
                    }}
                  >
                    <ReactMarkdown source={breakingNews.content} />
                  </Typography>
                </Box>
              </Alert>
            </Box>
          </Container>
        )}

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          width="100%"
          className={classes.wrapper}
          style={{ color: businessUnit.ColorHeaderText }}
          position="relative"
        >
          <IconButton
            color="inherit"
            className={clsx(
              (date === today ||
                (businessUnit.SkipSundays &&
                  moment(date).day() === 1 &&
                  moment(date).subtract(2, "days") < moment(today))) &&
              classes.btnDisabled
            )}
            disabled={
              date === today ||
              (businessUnit.SkipSundays &&
                moment(date).day() === 1 &&
                moment(date).subtract(2, "days") < moment(today))
            }
            onClick={handlePrev}
          >
            <ChevronLeftIcon className={classes.arrow} />
          </IconButton>
          <Typography
            variant="h4"
            align={xs ? "left" : "center"}
            style={!xs ? undefined : { flexGrow: 1 }}
          >
            {date && (
              <Moment
                locale="de"
                format={xs ? "ddd, DD.MM.YYYY" : "ddd, D. MMMM YYYY"}
              >
                {date}
              </Moment>
            )}
          </Typography>
          <IconButton
            color="inherit"
            onClick={handleOpenCalendar}
            className={classes.calendarBtn}
          >
            <CalendarIcon className={classes.arrow} />
          </IconButton>
          <IconButton color="inherit" onClick={handleNext}>
            <ChevronRightIcon className={classes.arrow} />
          </IconButton>
        </Box>
      </Box>
      <Box px={2} pt={0} pb={4} mt={-6} className={classes.wrapper}>
        {!loading && slots &&
          slots.map((slot) => (
            <Slot key={slot.id} slot={slot} loading={loading} reloadSlots={loadSlots} />
          ))}
      </Box>
      {loading && (
        <Box p={8}>
          <div className={classes.loadingIconWrapper}>
            <LoadingIcon className={classes.loadingIcon} />
          </div>
        </Box>
      )}
      {!loading && slots && !slots.length && (
        <Box p={8}>
          <Typography align="center" color="textSecondary" variant="body2">
            Für dieses Datum ist noch keine Anmeldung möglich
          </Typography>
        </Box>
      )}
      {auth &&
        auth.token &&
        businessUnit.Permissions &&
        businessUnit.Permissions.EditSlot && (
          <>
            <Box pb={4} textAlign="center">
              <IconButton color="inherit" onClick={handleOpenCreate} style={{
                color: businessUnit.ColorButtonBackground,
              }}>
                <PlusIcon className={classes.iconCreate} />
              </IconButton>
            </Box>
            <SlotEdit
              slotDate={date}
              handleClose={handleCloseCreate}
              open={openCreate}
            />
          </>
        )}
      <Popover
        id={calendarId}
        open={calendarOpen}
        anchorEl={anchorEl}
        onClose={handleCloseCalendar}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Calendar
          onChange={onChangeCalendar}
          value={new Date(date)}
          next2Label={null}
          prev2Label={null}
          tileClassName={({ activeStartDate, date, view }) =>
            moment(date).format("YYYY-MM-DD") <
              moment(new Date()).format("YYYY-MM-DD") ||
              (businessUnit.SkipSundays && date.getDay() === 0)
              ? "disabledDay"
              : null
          }
          tileContent={({ activeStartDate, date, view }) => {
            const d = moment(date).format("YYYY-MM-DD");
            const available = availabilityInformation[d] > 0;
            const notAvailable = availabilityInformation[d] <= 0;
            return (
              <span
                className={clsx(
                  classes.dot,
                  available && clsx(classes.dotAvailable),
                  notAvailable && classes.dotNotAvailable
                )}
              />
            );
          }}
        />
      </Popover>
      <Pusher
        channel={
          process.env["REACT_APP_PUSHER_CHANNEL" + window.ENV_POSTFIX] ||
          process.env.REACT_APP_PUSHER_CHANNEL
        }
        event={"seatsChanged" + businessUnit.MachineName}
        onUpdate={handlePusher}
      />
    </>
  );
};

export default Home;
