import React, { useEffect, useState } from "react";
import {
  Typography,
  makeStyles,
  Box,
  Button,
  Container,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import Lottie from "lottie-react-web";
import CheckAnimation from "../../assets/check_animation.json";
import AlertIcon from "mdi-react/AlertOutlineIcon";
import useScreensize from "../../hooks/useScreensize";
import clsx from "clsx";
import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import API from "../../utils/API";
import { useRef } from "react";
import useValues from "@bit/wamedia.tasso.use-values";
import TsTextField from "@bit/wamedia.tasso.ts-text-field";

const anim = JSON.stringify(CheckAnimation);

const useStyles = makeStyles((theme) => ({
  header: {
    background: theme.palette.secondary.main,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    position: "relative",
  },
  growWrapper: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
  btnDownload: {
    color: "#fff",
    marginTop: theme.spacing(2),
  },
  alertIcon: {
    width: 80,
    height: 80,
    marginBottom: theme.spacing(2),
  },
  alertIconMobile: {
    width: 60,
    height: 60,
    marginRight: theme.spacing(2),
  },
  hint: {
    marginBottom: theme.spacing(2),
  },
  hintTitle: {
    flexGrow: 1,
  },
}));

const STATUS_CHECK_COUNT_MAX = 20;

const initialValues = {
  email: "",
};

const Success = ({ match }) => {
  const classes = useStyles();
  const [animationCompleted, setAnimationCompleted] = useState(false);
  const { xs, sm, md, lg, xl } = useScreensize();
  const businessUnit = useSelector((state) => state.businessUnit);
  const [status, setStatus] = useState(null);
  const [statusCheckCount, setStatusCheckCount] = useState(0);
  const [email, setEmail] = useState(null);
  const [isShopOrder, setIsShopOrder] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const { values, setValues, handleChangeValue } = useValues(initialValues);
  const [open, setOpen] = useState(false);
  const statusCheckCountRef = useRef(null);
  statusCheckCountRef.current = statusCheckCount;

  useEffect(() => {
    setTimeout(() => {
      setAnimationCompleted(true);
    }, 2400);
  }, []);

  useEffect(() => {
    if (!match.params.uuid) {
      return;
    }

    checkStatus();
  }, [match.params.uuid]);

  if (!businessUnit) {
    return null;
  }

  const checkStatus = () => {
    API.get("/bookings/status/" + match.params.uuid)
      .then((result) => {
        const statusResult = result.data ? result.data : null;
        if (statusResult && statusResult.status) {
          setStatus(statusResult.status);
          setEmail(statusResult.email);
          setIsShopOrder(statusResult.isShopOrder);
          setStatusCheckCount((count) => count + 1);
          if (
            statusResult.status === "draft" &&
            statusCheckCountRef &&
            statusCheckCountRef.current < STATUS_CHECK_COUNT_MAX
          ) {
            setTimeout(() => {
              checkStatus();
            }, 1500);
          }
        }
      })
      .catch((error) => { });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveNewEmail = () => {
    if (!values.email || !match.params.uuid) {
      return;
    }

    setIsSending(true);
    API.post("/bookings/change-email/" + match.params.uuid, {
      email: values.email,
    })
      .then((result) => {
        // const statusResult = result.data ? result.data : null;
        setIsSending(false);
        window.location.reload();
      })
      .catch((error) => {
        setIsSending(false);
      });
  };

  const hexToRgb = (hex) => {
    if (hex.replace("#", "").length === 3) {
      hex = hex
        .split("")
        .map((m) => (m === "#" ? m : `${m}${m}`))
        .join("");
    }
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
      : null;
  };

  const { r: red, g: green, b: blue } = hexToRgb(
    businessUnit.ColorButtonBackground
  );
  const animColored = JSON.parse(
    anim
      .replace("0.9411764705882353", red / 255)
      .replace("0.5490196078431373", green / 255)
      .replace("0.00392156862745098", blue / 255)
  );

  return (
    <>
      <Box
        className={classes.header}
        py={4}
        px={0.5}
        height={isShopOrder ? 400 : 300}
        style={{
          backgroundColor: businessUnit.ColorHeaderBackground,
          color: businessUnit.ColorHeaderText,
        }}
      >
        {!animationCompleted && (
          <Lottie
            width={300}
            options={{
              animationData: animColored,
              loop: false,
            }}
          />
        )}

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className={classes.growWrapper}
        >
          <Grow in={animationCompleted}>
            <Box p={4} textAlign="center">
              <Typography variant="h1" gutterBottom>
                Anmeldung erfolgreich
              </Typography>
              <Container maxWidth="sm">
                {status === "completed" && (
                  <Typography variant="body1" gutterBottom>
                    Wir haben soeben eine E-Mail mit der Bestätigung Ihrer
                    Anmeldung an{" "}
                    <strong>{email || "die angegebene Adresse"}</strong>{" "}
                    gesendet.
                  </Typography>
                )}

                {status === "draft" && (
                  <Typography variant="body1" gutterBottom>
                    Ihre Buchung wird bearbeitet. Sie erhalten in wenigen
                    Minuten eine Bestätigung per E-Mail an{" "}
                    <strong>{email || "die angegebene Adresse"}</strong>
                  </Typography>
                )}
              </Container>
              {status === "completed" && (
                <Box mt={2}>
                  <Typography variant="body2">
                    <strong>Keine E-Mail erhalten?</strong>
                    <br />
                    Laden Sie sich Ihre Bestätigung bitte über folgenden Button
                    herunter!
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: businessUnit.ColorButtonBackground,
                    }}
                    className={classes.btnDownload}
                    component="a"
                    target="_blank"
                    href={
                      (process.env["REACT_APP_API_URL" + window.ENV_POSTFIX] ||
                        process.env.REACT_APP_API_URL) +
                      "bookings/download/" +
                      match.params.uuid
                    }
                  >
                    Anmeldung herunterladen
                  </Button>
                </Box>
              )}
              {isShopOrder && (
                <Box mt={2}>
                  <Typography variant="body2">
                    <strong>Falsche E-Mail-Adresse?</strong>
                    <br />
                    Über folgenden Button können Sie Ihre E-Mail-Adresse
                    korrigieren
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: businessUnit.ColorButtonBackground,
                    }}
                    className={classes.btnDownload}
                    onClick={handleOpen}
                  >
                    E-Mail-Adresse korrigieren
                  </Button>
                </Box>
              )}
            </Box>
          </Grow>
        </Box>
      </Box>
      <Fade
        in={animationCompleted && Boolean(businessUnit.SuccessAttentionText)}
        style={{ transitionDelay: "500ms" }}
      >
        <Container maxWidth="sm">
          {!xs && (
            <Box px={{ xs: 3, lg: 3 }} pt={4} textAlign="center">
              <AlertIcon className={classes.alertIcon} />
            </Box>
          )}

          <Box px={{ xs: 3, lg: 3 }} pb={4} textAlign={xs ? "left" : "center"}>
            <Box
              display="flex"
              pt={{ xs: 4, md: 0 }}
              pb={2}
              alignItems="center"
              textAlign={xs ? "left" : "center"}
            >
              {xs && <AlertIcon className={classes.alertIconMobile} />}
              <Typography
                variant="h5"
                className={clsx(!xs && classes.hintTitle)}
              >
                {businessUnit.SuccessAttentionTitle}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              component="div"
              className={classes.hint}
            >
              <div id="hints">
                <ReactMarkdown source={businessUnit.SuccessAttentionText} />
              </div>
            </Typography>
          </Box>
        </Container>
      </Fade>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">E-Mail-Adresse ändern</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bitte geben Sie eine E-Mail-Adresse ein und klicken Sie auf
            übernehmen. Ihre Bestätigung wird dann an diese E-Mail-Adresse
            gesendet.
          </DialogContentText>
          <TsTextField
            value={values.email}
            handleChange={handleChangeValue}
            name="email"
            label="E-Mail-Adresse"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button
            disabled={isSending || !values.email}
            onClick={handleSaveNewEmail}
            variant="contained"
            color="primary"
            style={{
              backgroundColor: businessUnit.ColorButtonBackground,
            }}
          >
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Success;
